const ScrollTo = {
  init: function () {
    $('body').on('click', '.jsScrollToAction', function() {
      let scrollTo = $(this).data('scrollto');
      $('html, body').animate({
        scrollTop: $('[data-scrolltothis="'+scrollTo+'"]').offset().top - 55
      }, 500);
      return false;
    });
  }
};
ScrollTo.init();
