const Root = {
  setWindowInnerHeight: async function () {
    let windowHeight = window.innerHeight;
    document.documentElement.style.setProperty('--vh', `${windowHeight}px`);
  },
  init: function () {
    $(window).on('load resize', function(){
      Root.setWindowInnerHeight();
    });
  }
};
Root.init();
