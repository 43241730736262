const WebpSupport = {
  supportsWebp: async function () {
    if (!self.createImageBitmap) return false;
    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
  },
  init: function () {
    (async () => {
      if(await WebpSupport.supportsWebp()) {
        let $body = $('body');
        $body.removeClass('no-webp');
        $body.addClass('webp');
      }
    })();
  }
};
WebpSupport.init();
