const Header = {
  init: function () {
    const header = $('.jsHeader');
    const headerExpander = $('.jsHeaderExpander');
    const windows = $(window);

    windows.scroll(function() {
      let scroll = windows.scrollTop();
      let heroHeight = $('.jsHero').height();

      if (scroll >= heroHeight) {
        header.addClass("--scroll");
      } else {
        header.removeClass("--scroll");
      }
    })

    $('body').on('click', '.jsHeaderExpander', function() {
      header.toggleClass('--show');
      headerExpander.toggleClass('--open');
    })
  }
};
Header.init();
