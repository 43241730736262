$('document').ready(function(){
  require("custom/_js-support")
  require("custom/_lazyloading")
  require("custom/_webp-support")
  require("custom/_root")
  require("custom/_scroll-to")
  require("custom/_fancybox")
  require("custom/_rellax")
  require("custom/_viewportchecker")
  require("custom/header")
  require("custom/about")
});
