require('@fancyapps/fancybox');
const FancyBox = {
  initFancybox: async function () {
   $('[data-fancybox]').fancybox({
     protect: true,
     touch: true,
     wheel: false,
     hash: false
   });
  },
  init: async function () {
    $(window).on('load', function(){
      FancyBox.initFancybox();
    });
  }
};
FancyBox.init();
