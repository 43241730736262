const myRellax = {
  init: function () {
    const rellaxExist = $('.rellax').length > 0;
    if (rellaxExist === true) {
      const Rellax = require('rellax');
      const rellax = new Rellax('.rellax');
      function rellaxCalc() {
        let widtheee = $(window).width();
        if (widtheee < 992) {
          rellax.destroy();
        } else {
          rellax.refresh();
        }
      }
      $(window).on('load resize', function(){
        rellaxCalc();
      });
    }
  }
};
myRellax.init();
