const About = {
  init: function () {
    const slider = $('.jsBeginningsSlider');
    const sliderExist = slider.length > 0;

    if (sliderExist === true) {
      $(window).on('load resize', function(){

        let slideWidth = slider.children().eq(0).width();
        let slideHeight = slider.children().eq(0).height();

        let slideWidthRatio = slideHeight * (3/4); //ratio 3:4
        let slideHeightRatio = slideWidth * (4/3); //ratio 3:4

        document.documentElement.style.setProperty('--slider-ratio-widht', `${slideWidthRatio}px`);
        document.documentElement.style.setProperty('--slider-ratio-height', `${slideHeightRatio}px`);
      });
      $(window).on('load', function(){

        slider.scrollLeft(0);
        slider.addClass('--snap');
      });

    }

    $('body').on('click', '.jsBeginningsSliderPrev', function() {
      let leftPos = slider.scrollLeft();
      let childWidth = slider.children().eq(0).find('img').width();
      slider.removeClass('--snap');
      slider.animate({
        scrollLeft: leftPos - childWidth - 24
      }, 250);
      slider.delay(5).queue(function(){
        slider.addClass('--snap').dequeue();
      });
    });

    $('body').on('click', '.jsBeginningsSliderNext', function() {
      let leftPos = slider.scrollLeft();
      let childWidth = slider.children().eq(0).find('img').width();
      slider.removeClass('--snap');
      slider.animate({
        scrollLeft: leftPos + childWidth + 24
      }, 250);
      slider.delay(5).queue(function(){
        slider.addClass('--snap').dequeue();
      });
    });
  }
};
About.init();
